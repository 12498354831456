/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { CardsWidgetDQ } from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetDQ'
import { CardsWidgetInsights } from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetInsights'
import { CardsWidgetML } from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetML'
import { CardsWidgetAI } from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetAI'

import // ListsWidget2,
  // ListsWidget3,
  // ListsWidget4,
  // ListsWidget6,
  // TablesWidget5,
  // TablesWidget10,
  // MixedWidget8,
  // CardsWidget7,
  // CardsWidget17,
  // CardsWidget20,
  // ListsWidget26,
  // EngageWidget10,
  // EngageWidget10,
  '../../../_metronic/partials/widgets'
import { left } from '@popperjs/core'
import { CardsDashWidget } from '../../../_metronic/partials/widgets/_new/cards/CardsDashWidget'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'> */}
    {/* begin::Col */}
    {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />
      </div> */}
    {/* end::Col */}

    {/* begin::Col */}
    {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div> */}
    {/* </div> */}
    {/* end::Row */}
    <CardsDashWidget img={toAbsoluteUrl("/media/dash/dash1.png")} />
    
    {/* Begin::Row */}
    {/*<div className="row" style={{ marginTop: "-25px" }}>
     <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <CardsWidgetDQ img={toAbsoluteUrl("/media/dash/DQ.png")} />
      </div>

      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <CardsWidgetInsights img={toAbsoluteUrl("/media/dash/insight.png")} />
      </div> 
    </div>*/}
    {/* End::Row */}


    {/* Begin::Row */}
    {/* <div className="row g-5 g-xl-5">
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <CardsWidgetML img={toAbsoluteUrl("/media/dash/ml.png")} />
      </div>

      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <CardsWidgetAI img={toAbsoluteUrl("/media/dash/AI.png")} />
      </div>
    </div> */}
    {/* End::Row */}


    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />       
      </div>
    </div> */}
    {/* end::Row */}

    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
  </>
)

const DashboardWrapper: FC = () => {
  // const intl = useIntl()
  return <DashboardPage />
}

export { DashboardWrapper }

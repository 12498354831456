type Props = {
  img: string
}

// const CardsWidgetDQ = ({img}: Props) => (
//   <div className='card-flush'>
//     <div className='card-body'>
//       <img src={img} alt='Data Quality' className='w-100' />
//     </div>
//   </div>
// )

const CardsDashWidget = ({ img }: Props) => (
  <div
    className="card-flush d-flex align-items-center justify-content-center custom-padding"
    style={{ width: "100%", paddingTop: "0px", paddingBottom: "0px" }}
  >
    <img src={img} alt="Data Quality" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
  </div>
);



export {CardsDashWidget}
